import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import Connection from './components/Connection'

import "./assets/main.css"

Vue.config.productionTip = false
Vue.use(VueRouter)

const router = new VueRouter({
    routes: [
        {
            path: '/viewer/:url', component: Connection,
            props: {
                default: true
            }
        }
    ]
})

new Vue({
    render: h => h(App),
    router: router,
}).$mount('#app')
