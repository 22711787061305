<template>
  <div>
    <header class="dronisos-header">
      <div class="dronisos-header--logo"></div>
      <div class="dronisos-header--title">Dronisos Choreography Viewer</div>
      <div class="spacer"></div>
      <div class="dronisos-header--links">
        <a href="https://www.dronisos.com/">About Dronisos</a>
      </div>
    </header>
    <div class="player">
      <div id="unity-container" class="player-body unity-desktop">
        <div class="player-unity">
          <canvas id="unity-canvas" tabindex="-1"></canvas>
        </div>
        <div id="unity-loading-bar">
          <div id="unity-logo"></div>
          <div id="unity-progress-bar-empty">
            <div id="unity-progress-bar-full"></div>
          </div>
        </div>
        <div id="unity-warning"></div>
      </div>
    </div>
    <footer class="player-footer" id="unity-footer">
      <div class="player-footer--title">{{ this.title }}</div>
      <div class="spacer"></div>
      <div id="unity-fullscreen-button" class="player-footer--fullscreen"><span>Fullscreen</span></div>
    </footer>
  </div>
</template>

<script>

export default {
  props: ['title', 'buildUrl', 'viewerJson'],
  name: 'Unity',
  data() {
    return {
      containerId: 'unity-container-' + Number(Math.random().toString().substr(3, length) + Date.now()).toString(36),
      gameInstance: null,
      loaded: false,
      progress: 0,
      error: null,
      id: -1
    }
  },
  methods: {
    fullscreen() {
      this.gameInstance.SetFullscreen(1)
    },
    updateBannerVisibility() {
      this.warningBanner.style.display = this.warningBanner.children.length ? 'block' : 'none';
    },
    updateCanvasSize() {
      let player = document.querySelector('.player');
      if (player.offsetHeight * 16 / 9 > player.offsetWidth) {
        this.canvas.style.width = player.offsetWidth + "px";
        this.canvas.style.height = (player.offsetWidth * 9 / 16) + "px";
        this.player.style.height = this.canvas.style.height;
        this.footer.style.paddingLeft = "8px";
        this.footer.style.paddingRight = "8px";
      }
      else {
        this.canvas.style.width = (player.offsetHeight * 16 / 9) + "px";
        this.canvas.style.height = player.offsetHeight + "px";
        this.player.style.height = "calc(100vh - 128px)";
        this.footer.style.paddingLeft = "0px";
        this.footer.style.paddingRight = "0px";
      }

      this.footer.style.width = this.canvas.style.width;
    },
    instantiate() {
      // eslint-disable-next-line no-undef
      createUnityInstance(this.canvas, this.config,
        (progress) => {
          this.progressBarFull.style.width = 100 * progress + "%";
        }).then((unityInstance) => {
          this.gameInstance = unityInstance;
          this.loadingBar.style.display = "none";
          this.fullscreenButton.onclick = () => {
            unityInstance.SetFullscreen(1);
          };
        }).catch((message) => {
          alert(message);
        });
    },
    unityShowBanner(msg, type) {
      const div = document.createElement('div');
      div.innerHTML = msg;
      this.warningBanner.appendChild(div);
      if (type === 'error')
        div.style = 'background: red; padding: 10px;';
      else {
        if (type === 'warning')
          div.style = 'background: yellow; padding: 10px;';
        setTimeout(function () {
          this.warningBanner.removeChild(div);
          this.updateBannerVisibility();
        }, 5000);
      }
      this.updateBannerVisibility();
    },
    sendMessageToUnity(gameObject, method, param) {
      if (param === null) {
        param = ''
      }
      if (this.gameInstance !== null) {
        this.gameInstance.SendMessage(gameObject, method, param)
      } else {
        console.warn('vue-unity-webgl: you\'ve sent a message to the Unity content, but it wasn\t instantiated yet.')
      }
    }
  },
  mounted() {
    this.container = document.querySelector("#unity-container");
    this.player = document.querySelector(".player");
    this.canvas = document.querySelector("#unity-canvas");
    this.footer = document.querySelector('.player-footer');
    this.loadingBar = document.querySelector("#unity-loading-bar");
    this.progressBarFull = document.querySelector("#unity-progress-bar-full");
    this.fullscreenButton = document.querySelector("#unity-fullscreen-button");
    this.warningBanner = document.querySelector("#unity-warning");

    const loaderUrl = this.buildUrl + "/unity.loader.js";
    this.config = {
      dataUrl: this.buildUrl + "/unity.data.unityweb",
      frameworkUrl: this.buildUrl + "/unity.framework.js.unityweb",
      codeUrl: this.buildUrl + "/unity.wasm.unityweb",
      streamingAssetsUrl: "StreamingAssets",
      companyName: "Dronisos",
      productName: "Dronisos drone choreography",
      productVersion: "1.0",
      showBanner: this.unityShowBanner
    };

    // By default, Unity keeps WebGL canvas render target size matched with
    // the DOM size of the canvas element (scaled by window.devicePixelRatio)
    // Set this to false if you want to decouple this synchronization from
    // happening inside the engine, and you would instead like to size up
    // the canvas DOM size and WebGL render target sizes yourself.
    // config.matchWebGLToCanvasSize = false;

    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      // Mobile device style: fill the whole browser client area with the game canvas:

      var meta = document.createElement('meta');
      meta.name = 'viewport';
      meta.content = 'width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes';
      document.getElementsByTagName('head')[0].appendChild(meta);
      this.container.className = "unity-mobile";
      this.canvas.className = "unity-mobile";

      // To lower canvas resolution on mobile devices to gain some
      // performance, uncomment the following line:
      // config.devicePixelRatio = 1;

    } else {
      this.updateCanvasSize();
    }

    window.onresize = () => { this.updateCanvasSize(); };

    this.loadingBar.style.display = "block";

    if (this.buildUrl) {
      const script = document.createElement('script')
      script.setAttribute('src', loaderUrl)
      script.setAttribute('async', '')
      script.setAttribute('defer', '')
      script.onload = () => {
        this.instantiate();
      };
      document.body.appendChild(script)
    }

    const loadWhenReady = () => {
      if (window.isUnityReady === true && this.id != null && this.viewerJson !== "") {
        this.sendMessageToUnity("LoadingManager", "SetApiUrl", process.env.VUE_APP_API_URL + "/v1/");
        this.sendMessageToUnity("LoadingManager", "ImportFromJson", this.viewerJson);
      } else {
        setTimeout(loadWhenReady, 200);
      }
    }

    loadWhenReady();
  }
}
</script>