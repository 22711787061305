<template>
  <div v-if="checkUrl === true">
    <div v-if="connected === false" class="login-window">
      <div class="login-overlay">
        <h1>Dronisos Choreography Viewer</h1>
        <div class="login-form" id="password-form">
          <label>The choreography is protected, enter the password below:</label>
          <input aria-invalid="false" class="login-password" id="password-id" type="password" placeholder="Enter Password" @keyup.enter="loadUnity()">
          <div class="login-form-button">
            <p class="login-error"></p>
            <button class="login-submit" @click="loadUnity">Submit</button>
          </div>
        </div>
        <div class="login-logo">
          <img src="../assets/dronisos.png" />
        </div>
      </div>
    </div>
    <div v-else>
      <Unity :title="title" :viewer-json="viewerJson" buildUrl="./unity/Build"></Unity>
    </div>
  </div>
</template>

<script>
import Unity from "@/components/Unity";

export default {
  name: "Connection",
  components: { Unity },
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      connected: false,
      checkUrl: false,
      title: '',
      viewerJson: ''
    }
  },
  methods: {
    loadUnity: function (has_password = true) {
      let json = {
        "password": ''
      }

      if (has_password) {
        json = {
          "password": document.getElementById('password-id').value
        }
      }

      const form = document.getElementById('password-form');
      const request = new XMLHttpRequest();
      const that = this;

      request.addEventListener('readystatechange', function () {
        if (this.readyState === this.DONE) {
          if (form != null)
            form.getElementsByClassName('login-submit')[0].disabled = false;

          if (this.status === 200) {
            that.viewerJson = this.responseText;
            that.connected = true;
            that.checkUrl = true;
          } else {
            if (has_password && form != null) {
              form.getElementsByClassName('login-password')[0].setAttribute('aria-invalid', 'true');
              form.getElementsByClassName('login-error')[0].innerHTML = 'Invalid password';
            }
          }
        }
      });
      request.open('POST', process.env.VUE_APP_API_URL + '/v1/viewers/' + this.$route.params.url, true);
      request.setRequestHeader('Content-Type', 'application/json');
      request.send(JSON.stringify(json));

      // Signify request is processing
      if (form != null)
        form.getElementsByClassName('login-submit')[0].disabled = true;
    }
  },
  mounted() {
    const request = new XMLHttpRequest();
    const that = this;
    request.addEventListener('readystatechange', function () {
      if (this.readyState === this.DONE) {
        const json = JSON.parse(this.responseText);
        if ("has_password" in json) {
          if (json["has_password"] === false) {
            that.loadUnity(false);
          } else {
            that.checkUrl = true;
          }
          that.title = json.title;
        }
      }
    });
    request.open('GET', process.env.VUE_APP_API_URL + '/v1/viewers/' + this.$route.params.url, true);
    request.send();
  }
}
</script>

<style scoped>
.login-window {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/Background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.login-window * {
  font-family: 'Roboto', Helvetica, Arial;
}

.login-overlay {
  border-radius: 1rem;
  padding: 1.5rem 5rem;
  background-color: rgba(54, 54, 54, 0.85);
  width: 700px;
  max-width: 95%;
  margin: auto;
}

.login-overlay h1 {
  font-size: 1.75rem;
  margin: 0.25rem auto 1rem auto;
  color: rgb(255, 255, 255);
  text-align: center;
  text-transform: uppercase;
}

.login-form>* {
  display: block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.login-form label {
  font-size: 1.2rem;
}

.login-form-button {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}

.login-password {
  background-color: rgba(255, 255, 255, 0.08);
  color: rgb(255, 255, 255);
  border-radius: 0.4rem;
  border: 1px solid rgba(0, 0, 0, 0);
  height: 3rem;
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-size: 1.5rem;
}

.login-submit {
  border: none;
  border-radius: 0.4rem;
  background-color: #F5F5F5;
  font-weight: 700;
  font-size: 1.25rem;
  padding: 0px;
  height: 3rem;
  width: 116px;
  text-transform: uppercase;
  text-align: center;
}

.login-submit:disabled {
  background-color: #a8a8a8;
}

.login-password[aria-invalid="true"] {
  border: 1px solid #ff5252;
}

.login-error {
  font-size: 1.25rem;
  color: #ff5252;
  font-size: 1.25rem;
  padding: 8px 16px 8px 0px;
  margin: 0px;
}

.login-logo>img {
  display: block;
  margin: 1rem auto 0rem auto;
  width: 160px;
}
</style>
